import React, {useState, useEffect} from "react"
import { Link, graphql } from "gatsby"

import {
    MdArrowBack,

} from 'react-icons/md';


import data from '../data-json/comercios.json'
import dataChannel from '../data-json/canales.json'

import SEO from "../components/seo"
import Layout from "../components/layout"

const Registrate = ({ data: { image1,icon_register } }) => {

    const [comercios, setComercios] = useState(data.comercios);
    const [c_stateBtn, setC_stateBtn] = useState(false);
    const [c_titleBtn, setC_titleBtn] = useState("Enviar");
    const [channel, setChannel] = useState(dataChannel.canales.filter(ca => ca.comercio === "Canal Tradicional"));

    const refComercio = React.createRef();

    const send_form = (e) => {
        if( typeof window !== `undefined`){
            e.preventDefault();
            setC_stateBtn(true);
            setC_titleBtn("Enviando...");

            setTimeout(() => {
                document.querySelector('#form_register').submit();
            }, 8000);
        }
    }


    return (
        <Layout changeHeader={2}>
            <SEO title={`Regístrate`} />

            <section className="hero-wrap hero-wrap-2 mb-2 top_btn" style={{ backgroundImage: `url(${image1.childImageSharp.fluid.src})`, height: '450px' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-1" data-sal="slide-right" data-sal-duration="2000" data-sal-easing="ease">
                            <Link to="/#" className="icon ml-5 btn btn-primary" title="Regresar">
                                <MdArrowBack
                                    color="#fff"
                                    size="2.3em"
                                />
                            </Link>

                        </div>
                        <div className="col-md-11 text-center">
                            <h1 className="mb-2 bread" data-sal="slide-left" data-sal-duration="2000" data-sal-easing="ease">Regístrate </h1>
                            <p className="breadcrumbs"><span className="mr-2">

                                <Link className="" to="/">Inicio</Link> > </span> <span>Regístrate</span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section contact-section ftco-no-pb" id="contact-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center " data-sal="slide-up" data-sal-duration="1800" data-sal-easing="ease">
                            <h2 className="mb-4">Sé parte de nuestra Red de Distribución</h2>
                            <p>Ingresa los datos necesarios y regístrate te contactaremos lo más pronto posible.</p>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4 text-center p-5 mt-5" data-sal="slide-right" data-sal-duration="1500" data-sal-easing="ease">
                            <img src={icon_register.childImageSharp.fluid.src} className="img-fluid" alt="Ilustración de registro"/>
                        </div>
                        <div className="col-md-7 " data-sal="slide-left" data-sal-duration="1500" data-sal-easing="ease">
                            <form
                                action="https://api-email.metalinks.com.ve/api/v1/send/eyJpdiI6IkcyaFpMZUV4Mm12bzZtZFZcL1RhNHRnPT0iLCJ2YWx1ZSI6IjlJckN4R1wvYkxMZ3pmelUwTXZadk53PT0iLCJtYWMiOiJlMjgzM2U0NTQ5YWIzOWMyNjhkMmFiMDlmMDFkYjE3YTBhMTliYWU0MmJiNjE3MDZiNWY0Mzk2NWZiZmZiODQxIn0="
                                id="form_register"
                                method="POST"
                                onSubmit={send_form}
                                encType="multipart/form-data"

                            className="bg-light p-4 p-md-5 contact-form">
                                <div className="form-group">
                                    <input required type="text" name="person_contact" className="form-control" placeholder="Nombre y Apellido" />
                                </div>
                                <div className="form-group">
                                    <input required type="number" name="phone" className="form-control" placeholder="Teléfono" />
                                </div>
                                <div className="form-group">
                                    <input required type="text" name="name_businness" className="form-control" placeholder="Nombre comercial" />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="doc">Documento identificador (RIF/CI)</label>
                                    <input required type="file" name="document" className="form-control file" placeholder="Documento identificador (RIF)" />
                                </div>
                                <div className="form-group">
                                    <input required type="text" name="location" className="form-control " placeholder="Dirección del comercio" />
                                </div>
                                {/* <div className="form-group">
                                    <select required name="type_businness" className="form-control" id="type_businness"
                                        onChange={() => { searchChannel() }}
                                        ref={refComercio}
                                    >
                                        <option value="">Tipo de comercio</option>
                                        {comercios.map(function (com, i) {
                                            return <option
                                                        key={i}
                                                        value={com.name}
                                                    >
                                                        {com.name}
                                                    </option>
                                        })}
                                    </select>
                                </div> */}
                                {/* <div className="form-group">
                                    <select required name="channel" className="form-control" id="channel">
                                        <option value="">Comercio</option>
                                        {channel.map(function (cana, i) {
                                            return <option key={i} value={cana.name}>{cana.name}</option>
                                        })}
                                    </select>
                                </div> */}
                                <div className="form-group">
                                    <select required name="channel" className="form-control" id="channel">
                                        <option value="">Tipo de Comercio</option>
                                            <option  value="Abasto">Abasto</option>
                                            <option  value="Bodegas">Bodegas</option>
                                            <option  value="Bodegón mini market">Bodegón mini market</option>
                                            <option  value="Cadena nacional">Cadena nacional</option>
                                            <option  value="Cantinas escolares">Cantinas escolares</option>
                                            <option  value="Cines">Cines</option>
                                            <option  value="Confitería autoservicio">Confitería autoservicio</option>
                                            <option  value="Confitería mostrador">Confitería mostrador</option>
                                            <option  value="Cyber café">Cyber café</option>
                                            <option  value="Estación de servicio mostrador">Estación de servicio mostrador</option>
                                            <option  value="Farmacia regional">Farmacia regional</option>
                                            <option  value="Feria de verduras">Feria de verduras</option>
                                            <option  value="Fuente de soda">Fuente de soda</option>
                                            <option  value="Hotel">Hotel</option>
                                            <option  value="Kiosco">Kiosco</option>
                                            <option  value="Kiosco centro comercial">Kiosco centro comercial</option>
                                            <option  value="Licorerias">Licorerias</option>
                                            <option  value="Mayor de viveres autoservicio">Mayor de viveres autoservicio</option>
                                            <option  value="Mayor de viveres mostrador">Mayor de viveres mostrador</option>
                                            <option  value="Mixto mayor y supermercado">Mixto mayor y supermercado</option>
                                            <option  value="No tradicionales">No tradicionales</option>
                                            <option  value="Panadería">Panadería</option>
                                            <option  value="Parada inteligente">Parada inteligente</option>
                                            <option  value="Restaurantes">Restaurantes</option>
                                            <option  value="Supermercado independiente">Supermercado independiente</option>
                                            <option  value="Tienda de conveniencia">Tienda de conveniencia</option>

                                    </select>
                                </div>
                                <div className="form-group">
                                    <textarea required name="reference_point" id="" cols="30" rows="7" className="form-control" placeholder="Punto de referecia
                                    "></textarea>
                                </div>
                                <div className="form-group">
                                    <input disabled={c_stateBtn}  type="submit" value={c_titleBtn} className="btn btn-primary py-3 px-5" />
                                    {/* <button disabled={c_stateBtn} className="btn btn-primary py-3 px-5" onClick={() => send_form("form_register")}>
                                        {c_titleBtn}
                                    </button> */}
                                </div>
                                <input  required type="hidden" name="type_form" value="2" />

                            </form>

                        </div>

                        <div className="col-md-5 d-flex">

                        </div>
                    </div>
                </div>
            </section>




        </Layout>
    )
}

export default Registrate

export const pageQuery = graphql`
  query RegByDev {

    image1: file(relativePath: { eq: "banner_nostros.png" }) {
        childImageSharp {
            fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
            }
        }
    }
    icon_register: file(relativePath: { eq: "icon_register.png" }) {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }


  }
`
